const Reducer = (state, action) => {
switch (action.type) {
case "CATEGORY_LOADING":
return{
...state,
isLoading: true,
isError: false,
}
case "CATEGORY_SET":
return{
...state,
isLoading: false,
isError: false,
categoryData: action.payload,
}
case "CATEGORY_ERROR":
return{
...state,
isLoading: false,
isError: true,
}
case "PRODUCT_LOADING":
return{
...state,
isLoading: true,
isError: false,
}
case "PRODUCT_SET":
return{
...state,
isLoading: false,
isError: false,
productData: action.payload,
}
case "PRODUCT_ERROR":
return{
...state,
isLoading: false,
isError: true,
}
case "TABEL_LOADING":
return{
...state,
isLoading: true,
isError: false,
}
case "TABEL_SET":
return{
...state,
isLoading: false,
isError: false,
lisTabel: action.payload,
}
case "TABEL_ERROR":
return{
...state,
isLoading: false,
isError: true,
}
case "CUSTOMER_LOADING":
return{
...state,
isUserLoading: true,
isUserError: false,
}
case "CUSTOMER_SET":
return{
...state,
isUserLoading: false,
isUserError: false,
customers: action.payload,
}
case "CUSTOMER_ERROR":
return{
...state,
isUserLoading: false,
isUserError: true,
}
case "IP_LOADING":
return{
...state,
isLoading: true,
isError: false,
}
case "IP_SET":
return{
...state,
isLoading: false,
isError: false,
userIp: action.payload,
}
case "IP_ERROR":
return{
...state,
isLoading: false,
isError: true,
}
case "CART_LOADING":
return{
...state,
isLoading: true,
isError: false,
}
case "CART_SET":
let cart_Price = action.payload.reduce((initialVal, elem) => {
let { currPrice } = elem;
initialVal = parseFloat(initialVal) + parseFloat(currPrice);
return initialVal;
},0);
let cart_Items = action.payload.reduce((initialVal, elem) => {
let { stock } = elem;
initialVal = parseFloat(initialVal) + parseFloat(stock);
return initialVal;
},0);
return{
...state,
isLoading: false,
isError: false,
cartData: action.payload,
cartPrice: cart_Price,
cartItems: cart_Items,
}
case "CART_ERROR":
return{
...state,
isLoading: false,
isError: true,
}
case "TAX_LOADING":
return{
...state,
isLoading: true,
isError: false,
}
case "TAX_SET":
return{
...state,
isLoading: false,
isError: false,
tax: action.payload,
}
case "TAX_ERROR":
return{
...state,
isLoading: false,
isError: true,
}
case "ORDERS_LOADING":
return{
...state,
isLoading: true,
isError: false,
}
case "ORDERS_SET":
return{
...state,
isLoading: false,
isError: false,
allOrders: action.payload,
}
case "ORDERS_ERROR":
return{
...state,
isLoading: false,
isError: true,
}
case "USER_ORDERS_LOADING":
return{
...state,
isLoading: true,
isError: false,
}
case "USER_ORDERS_SET":
return{
...state,
isLoading: false,
isError: false,
userOrders: action.payload,
}
case "USER_ORDERS_ERROR":
return{
...state,
isLoading: false,
isError: true,
}
case "USER_TABEL_LOADING":
return{
...state,
isLoading: true,
isError: false,
}
case "USER_TABEL_SET":
return{
...state,
isLoading: false,
isError: false,
userTabels: action.payload,
}
case "USER_TABEL_ERROR":
return{
...state,
isLoading: false,
isError: true,
}
case "COUPON_LOADING":
return{
...state,
isLoading: true,
isError: false,
}
case "COUPON_SET":
return{
...state,
isLoading: false,
isError: false,
couponData: action.payload,
}
case "COUPON_ERROR":
return{
...state,
isLoading: false,
isError: true,
}
case "STORE_DATA_LOADING":
return{
...state,
isLoading: true,
isError: false,
}
case "STORE_DATA_SET":
return{
...state,
isLoading: false,
isError: false,
storeInfo: action.payload,
}
case "STORE_DATA_ERROR":
return{
...state,
isLoading: false,
isError: true,
}
case "STORE_SOCIAL_LOADING":
return{
...state,
isLoading: true,
isError: false,
}
case "STORE_SOCIAL_SET":
return{
...state,
isLoading: false,
isError: false,
storeSocial: action.payload,
}
case "STORE_SOCIAL_ERROR":
return{
...state,
isLoading: false,
isError: false,
}
case "LIST_ALL_ORDERS_LOADING":
return{
...state,
isLoading: true,
isError: false,
}
case "LIST_ALL_ORDERS_SET":
return{
...state,
isLoading: false,
isError: false,
listAllOrders: action.payload,
}
case "LIST_ALL_ORDERS_ERROR":
return{
...state,
isLoading: false,
isError: true,
}

default: return state;
}
}

export default Reducer;