import React from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const Header = () => {

const { customers } = GlobalContext();

const openSideMenu = () => {
$('#leftSideBar').fadeIn().show();    
}

const logOut = async() => {
try {
const sendData = await axios.get('/server/logout');
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Logout successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);   
}
} catch (error) {
console.log('backend logout-api error: ' + error);
}    
}

const ContactModal = () => {
$('#contactModal').fadeIn().show();
}

return (
<>
<div className='header'>
<div className='container'>
<div className='row'>
<div className='col'>
<div className='col2'>
<div className='menu-bar' onClick={() => openSideMenu()}>
<img src='../images/menu.gif' alt='logo' />
</div>
</div>
<div className='col2'>
<div className='logo'>
<Link to='/home'><img src='../images/logoMain.png' alt='logo' /></Link>
</div>
</div>
</div>
<div className='col'>
<div className='col-right'>
<div className='right-menus'>
<Link to='/cart'><img src='../images/shopping-cart.gif' alt='menus' /></Link>
<span className='dropMenu'>
<img src='../images/account.gif' alt='menus' />
<div className='dropDown'>
<div className='userTitle'><i className='fa fa-user'></i> {customers.length > 0 && customers[0].name}</div>
<ul>
<Link to='/my-account'><li><i className='fa fa-check-circle'></i> My Account</li></Link>
<Link to='/orders'><li><i className='fa fa-check-circle'></i> Orders</li></Link>
<Link to='/all-orders'><li><i className='fa fa-check-circle'></i> All Orders</li></Link>
<Link to='/'><li><i className='fa fa-check-circle'></i> Tabel List</li></Link>
<li onClick={() => logOut()}><i className='fa fa-check-circle'></i> Log Out</li>
</ul>
</div>
</span>
<img src='../images/contacts.gif' alt='menus' onClick={() => ContactModal()} />
</div>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default Header;
