import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TopHeader from './content/TopHeader';
import Header from './content/Header';
import LeftSidebar from './content/LeftSidebar';
import Home from './pages/Home';
import Product from './pages/Product';
import EnteryPage from './pages/EnteryPage';
import Cart from './pages/Cart';
import Orders from './pages/Orders';
import OrderDetails from './pages/OrderDetails';
import MyAccount from './pages/MyAccount';
import AutoScroll from './content/AutoScroll';
import TabelBook from './pages/TabelBook';
import AllOrders from './pages/AllOrders';
import Auth from './content/Auth';
import ContactModal from './content/ContactModal';

const App = () => {
return (
<BrowserRouter>
<Auth />
<AutoScroll />
<TopHeader />
<Header />
<ContactModal />
<LeftSidebar />
<Routes>
<Route path='/' element={<TabelBook />} />
<Route path='/home' element={<Home />} />
<Route path='/products/:name' element={<Product />} />
<Route path='/cart/' element={<Cart />} />
<Route path='/orders/' element={<Orders />} />
<Route path='/order-details/:id' element={<OrderDetails />} />
<Route path='/my-account/' element={<MyAccount />} />
<Route path='/all-orders/' element={<AllOrders />} />
<Route path='/login/' element={<EnteryPage />} />
</Routes>
</BrowserRouter>
)
}

export default App;
