import React, { useEffect, useState } from 'react';

const CopyRight = () => {
const [yearFull, setYearFull] = useState();
useEffect(() => {
setYearFull(new Date().getFullYear());
},[]);
return (
<>
<div className='copyright'>
<span>© copyright {yearFull} Fun & Food. All rights reserved.</span>
</div>
</>
)
}

export default CopyRight;
