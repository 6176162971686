import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { nanoid } from 'nanoid'
import { GlobalContext } from '../context/AppContext';

const CartPrice = () => {
const { cartPrice, cartItems, tax, cartData, couponData } = GlobalContext();

const taxPrc = tax.length > 0 && tax.reduce((e, g) => {
const { rate } = g;
let newPrc = (parseFloat(cartPrice) * parseFloat(rate)) / 100;
e += newPrc;
return e;
},0);

const matchCoupon = cartData.length > 0 && cartData.reduce((k, l) => {
const { couponName, currPrice } = l;
const item = k.find((u) => u.couponName === couponName);
item ? item.couponName = couponName : k.push({couponName, currPrice});
return k;
},[]);

const couponVal = matchCoupon.length > 0 && matchCoupon.map((l) => {
const checkVal = couponData.length > 0 && couponData.filter((y) => y.name === l.couponName).reduce((g, b) => {
const { rate, type } = b;
let prc = 0;
if(type === 'percent'){
prc = (parseFloat(l.currPrice) * parseFloat(rate)) / 100;
}
else{
prc = rate;
}
return g = prc;
}, 0);
return checkVal;
});

const placeOrder = () => {
var orderId = nanoid();    
cartData.forEach(async element => { 
var itemId = element.id;
var itemName = element.name;
var itemTypes = element.types;
var itemPrice = element.price;
var itemQuantity = element.quantity;
var itemStock = element.stock;
var tabelNo = element.tabel;
var user = element.user;
var contact = element.contact;
var coupon = element.couponName;
var payOpt = $('#payOpt').val();
var currPrc = 0;

if(itemQuantity === 'half'){
currPrc = parseFloat(itemPrice) / 2; 
}
else{
currPrc = parseFloat(itemPrice);  
}

try {
const sendData = await axios.post('/server/all-orders', {
orderId: orderId,
id: itemId,
itemName: itemName,
itemTypes: itemTypes,
itemPrice: itemPrice,
currPrc: currPrc,
itemQuantity: itemQuantity,
itemStock: itemStock,
tabelNo: tabelNo,
user: user,
contact: contact,
coupon: coupon,
payOpt: payOpt
});
const resData = await sendData.data;
if(resData){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Order placed successfull');  
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.replace('/orders');
},1000);  
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);  
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);  
}
} catch (error) {
console.log('backend order-placed-api error: ' + error);
}
});
}

return (
<>
<div className='cartPrice'>
<div className='cartPrcContent'>
<div className='headPrice-title'><span>Price Details</span></div>
<hr className='smHr' />

<div className='priceWrap'>
<div className='bodyCartPrc'>
<div className='col2'><span>Price :</span></div>
<div className='col2'><span>₹ {cartPrice}</span></div>
</div>
<div className='bodyCartPrc'>
<div className='col2'><span>Item No :</span></div>
<div className='col2'><span>{cartItems}</span></div>
</div>
{
couponVal[0] > 0 &&
<div className='bodyCartPrc'>
<div className='col2'><span>Coupon Discount :</span></div>
<div className='col2'><span>- ₹ {couponVal[0]}</span></div>
</div>
}

{
tax.length > 0 && tax.map((t, tdex) => {
let tPrc = (parseFloat(cartPrice) * parseFloat(t.rate)) / 100;
return (
<div className='bodyCartPrc' key={tdex}>
<div className='col2'><span>{t.name +' ('+ t.rate +'%)'} :</span></div>
<div className='col2'><span>{tPrc.toFixed(2)}</span></div>
</div>
)
})   
}
<div className='totalPrc'>
<div className='col2'><span>Total Price :</span></div>
<div className='col2'><span>₹ {((parseFloat(cartPrice) + parseFloat(taxPrc)) - parseFloat(couponVal[0])).toFixed(2)}</span></div>
</div>

<div className='headPrice-title'><span>Payment Options</span></div>
<hr className='smHr' />

<div className='priceWrapo'>
<div className='input-divRadio'>
<span><input type='radio' id='payOpt' name='payOpt' value='tabel_order' defaultChecked />Order On Tabel</span>
</div>
</div>

<div className='placeOrders'>
<button onClick={() => placeOrder()}>Place Order</button>
</div>

</div>

</div>
</div>
</>
)
}

export default CartPrice;
