import React from 'react';
import { GlobalContext } from '../context/AppContext';
import CopyRight from '../content/CopyRight';
import { useNavigate } from 'react-router-dom';

const Home = () => {
const { categoryData, customers } = GlobalContext();
const Navigate = useNavigate();

const openMenu = (name) => {
Navigate('/products/'+name);
}

return (
<>
<div className='bodyContent'>
<div className='bodyWrap'>
<div className='container'>

<div className='headContent'>
<span>{customers.length > 0 && 'Hello, Mr.'+ customers[0].name + ' Your '+  customers[0].tabelNo}</span>
</div>

<div className='cards-block'>
<div className='cardsWrap'>
{
categoryData.length > 0 && categoryData.map((data, index) => {
var itemType;
if (data.types === 'veg'){
itemType = <div className='vegDiv'><span></span></div>;   
}
else{
itemType = <div className='nonvegDiv'><span></span></div>;    
}
return (
<div className='cards' key={index} onClick={() => openMenu(data.name)}>
<div className='cards-body'>
<div className='animateDiv'>
<div className='card-content'>
<center><img src='../images/menu_menu.gif' alt='cards-img' /></center>   
<span>{data.name}</span>
<div className='typesWrap'>{itemType}</div>
</div>
</div>
</div>
</div>
)
})
}
</div>
</div>

<CopyRight />
</div>
</div>
</div>
</>
)
}

export default Home;
