import React from 'react';
import $ from 'jquery';
import { GlobalContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';

const LeftSidebar = () => {

const { categoryData, storeInfo } = GlobalContext();
const Navigate = useNavigate();

const closeSideMenu = () => {
$('#leftSideBar').hide();
}

const openSideMenu = (name) => {
Navigate('/products/'+name);
$('#leftSideBar').hide();
}

return (
<>
<div className='sideBar' id='leftSideBar'>
<div className='sideBar-wrap'>
<div className='sidebar-header'>
<span>Our Menu</span>
<span onClick={() => closeSideMenu()}><img src='../images/left-arrow.gif' alt='back' /> </span>
</div>

<div className='sideMemu'>
{
storeInfo.length > 0 &&
<div className='sideContactInfo'>
<img src='../images/phone.gif' alt='social' className='prayImg' />
<span>+{storeInfo[0].contact}</span>   
</div>
}
<ul>
{
categoryData.length > 0 && categoryData.map((data, index) => {
return(
<li key={index} onClick={() => openSideMenu(data.name)}><img src='../images/view.gif' alt='view' /> {data.name}</li>
)
})
}
</ul>
</div>

</div>
</div>
</>
)
}

export default LeftSidebar;
