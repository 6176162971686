import React from 'react';
import { GlobalContext } from '../context/AppContext';
import { Link, useNavigate } from 'react-router-dom';

const Orders = () => {
const { allOrders, userOrders, tax, couponData } = GlobalContext();
const Navigate = useNavigate();

const singleSet = userOrders.reduce((curr, elem) => {
const { dateTime, itemPrice, itemQuantity, itemStock, orderId, couponName} = elem;
const item = curr.find((it) => it.orderId === orderId);
var newPrc = 0;
if(itemQuantity === 'half'){
newPrc = (parseFloat(itemPrice) * parseInt(itemStock)) / 2; 
}
else{
newPrc = parseFloat(itemPrice) * parseInt(itemStock);   
}
item ? item.newPrc += newPrc : curr.push({dateTime, newPrc, orderId, couponName});
return curr;
},[]);

const viewOrders = (id) => {
Navigate('/order-details/'+id);
}

return (
<>
<div className='bodyContent'>
<div className='bodyWrap'>
<div className='container'>
<div className='headTitlePage'>
<span>Orders ({allOrders.length})</span>
</div>

<div className='products-page'>
{
allOrders.length > 0 ? allOrders.map((data, index) => {
var statusItem;
if(data.orderStatus === 'order placed' || data.orderStatus === 'confirmed'){
statusItem = <span className='statusVal'>{data.orderStatus}</span>;
}
else{
statusItem = <span className='statusValD'>{data.orderStatus}</span>;  
}
return (
<div className='orders-cards' key={index} onClick={() => viewOrders(data.orderId)}>
<div className='orders-cardHead'>
<div><span>Order Id :</span> <span>{data.orderId}</span></div>
<div><span className='orderSta'><span>Order status :</span> {statusItem}</span></div>
</div>
<div className='orders-cardsBody'>
{
singleSet.length > 0 && singleSet.filter((item) => item.orderId === data.orderId).map((data2, index2) => {
return (
<div key={index2}>
{
userOrders.length > 0 && userOrders.filter((item) => item.orderId === data2.orderId).map((data3, index3) => {
var itemType;
if(data3.itemTypes === 'veg'){
itemType = <span className='vegSpan'><span></span></span>;    
}
else{
itemType = <span className='nonvegSpan'><span></span></span>;  
}
return (
<div className='itemsNames' key={index3}>
{itemType}
<span className='itemsTitle'>{data3.itemStock} X {data3.itemName} <span>({data3.itemQuantity})</span></span>
</div>
)
})    
}
</div>
)
})
}
</div>

{
singleSet.length > 0 && singleSet.filter((item) => item.orderId === data.orderId).map((data4, index4) => {

const taxVal = tax.length > 0 && tax.reduce((c, v) => {
const { status, rate } = v;
let prc = 0;
if(status === 'enable'){
prc = (parseFloat(data4.newPrc) * parseFloat(rate)) / 100;
}
c += prc;
return c;
},0);

const couponVal = couponData.length > 0 && couponData.filter((e) => e.name === data4.couponName).reduce((b, n) => {
const { rate, type } = n;
let prc = 0;
if (type === 'percent'){
prc = (parseFloat(data4.newPrc) * parseFloat(rate)) / 100;
}
else{
prc = rate;
}
return b = prc;
},0);

return (
<div className='itemsFooter' key={index4}>
<div><span>Order Date : </span> <span>{data4.dateTime}</span></div>
<div><div><span>Total Price : </span> <span>₹ {((parseFloat(data4.newPrc) + parseFloat(taxVal)) - parseFloat(couponVal)).toFixed(2)}</span></div></div>
</div>
)
})
}

</div>
)   
})
:
<div className='NoCarts'>
<img src='../images/oops.png' alt='empty-cart' />
<span>Yet not any orders... Please do some orders...</span>
<Link to='/home'><button>Add Items</button></Link>
</div>
}
</div>

</div>
</div>
</div>
</>
)
}

export default Orders;
