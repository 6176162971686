import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const API6 = '/server/list-cart';

const AddCartModal = ({datas}) => {

const { customers, GetCartData, cartData } = GlobalContext();
const [getCart, setGetCart] = useState([]);
const [getOption, setGetOption] = useState('full');

const couponVal = cartData.length > 0 && cartData.filter((u) => customers.length > 0 && customers.some((p) => u.tabel === p.tabelNo && u.contact === p.contact)).reduce((k, l) => {
const { couponName } = l;
const item = k.find((j) => j.couponName === couponName);
item ? item.couponName = couponName : k.push({couponName});
return k;
},[]);

const closeModal = () => {
$('#cartModal').hide();
setGetCart([]);
}

const handleStock = (e) => {
var a = 0;
if(e.target.value < 1){
$('#quantity').val('1'); 
}  
var valPrc = $('#quantity').val();
var prc = $('#itemsPrcActive2').text();
a = parseFloat(prc) * parseInt(valPrc);
$('#itemsPrc').text(a);
}

const minusStock = () => {
var total = $('#quantity').val(); 
var a = 0;
if(total < 2){
$('#quantity').val('1');    
}  
else{
$('#quantity').val(parseInt(total) - 1);
} 
var valPrc = $('#quantity').val();
var prc = $('#itemsPrcActive2').text();
a = parseFloat(prc) * parseInt(valPrc);
$('#itemsPrc').text(a);
}

const plusStock = () => {
var total = $('#quantity').val(); 
var a = 0;
$('#quantity').val(parseInt(total) + 1);
var valPrc = $('#quantity').val();
var prc = $('#itemsPrcActive2').text();
a = parseFloat(prc) * parseInt(valPrc);
$('#itemsPrc').text(a);
}

const handleOpt = (e) => {
setGetOption(e.target.value); 
var valPrc = $('#quantity').val();
var prc = $('#itemsPrcActive').text(); 
var a = 0;
var b = 0;
if(e.target.value === 'half'){
a = parseFloat(prc)  * parseInt(valPrc) / parseInt(2);
b = parseFloat(prc) / parseInt(2);
$('#itemsPrcActive2').text(b); 
$('#itemsPrc').text(a); 
}
else{
$('#itemsPrcActive2').text(parseFloat(prc));    
$('#itemsPrc').text(parseFloat(prc) * parseInt(valPrc));    
}
}

const aaddItems = async() => {
var user = customers.length > 0 && customers[0].name;
var tabel = customers.length > 0 && customers[0].tabelNo;
var contact = customers.length > 0 && customers[0].contact;
var name = getCart[0].name;
var category = getCart[0].category;
var types = getCart[0].types;
var discription = getCart[0].discription;
var pathImg = getCart[0].path;
var quantity = getOption;
var couponName = couponVal && couponVal[0].couponName;
var stock = $('#quantity').val();
var price = $('#itemsPrcActive').text();
var currPrice = $('#itemsPrc').text();
//alert(name +' -- '+ category +' -- '+ discription +' -- '+ quantity +' -- '+ stock +' -- '+ price +' -- '+ currPrice);
try {
const sendData = await axios.post('/server/users-order-items', {
name: name,
category: category,
types: types,
discription: discription,
quantity: quantity,
couponName: couponName,
stock: stock,
price: price,
currPrice: currPrice,
user: user,
tabel: tabel,
contact: contact,
path: pathImg
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Item added successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
$('#cartModal').hide();
setGetCart([]);
setGetOption('full');
},1000);
return false;  
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000); 
}
} catch (error) {
console.log('backend add-items-api error: ' + error);
}
}

useEffect(() => {
setGetCart(datas);
GetCartData(API6);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[datas]);

return (
<>
<div className='modals' id='cartModal'>
<div className='modals-content'>
<div className='modals-header'><span>{getCart.length > 0 && getCart[0].category}</span> <i className='fa fa-close' onClick={() => closeModal()}></i></div>

<div className='modals-Body'>
{
getCart.length > 0 && getCart.map((data, index) => {
return (
<div className='cartContent' key={index}>
<div className='CartImg'><img src={'https://control.funandfood.co.in/'+ data.path} alt='cartimg' /></div>
<div className='cartProducts'>
<span className='product-title'>{data.name}</span>
<span className='product-discription'>{data.discription}</span>
</div>
<div className='cartsOption'>
<div className='inputRadius'>
<label>Select One Option</label>
<span><input type='radio' name='quantity' id='quantity1' value='half' onChange={handleOpt} />Half</span>
<span><input type='radio' name='quantity' id='quantity2' value='full' onChange={handleOpt} defaultChecked />Full</span>
</div>
</div>

<div className='orderStock'>
<div className='stuckDiv'>
<span>Order Quantity</span>
</div>
<div className='stuckDiv'>
<div className='plusMinus-input'>
<button onClick={() => minusStock()}><i className='fa fa-minus'></i></button>
<input type='number' id='quantity' defaultValue='1' onChange={handleStock} />
<button onClick={() => plusStock()}><i className='fa fa-plus'></i></button>
</div>
</div>
</div>

<div className='orderPrice'>
<div className='priceDiv'>
<span>Item Price</span>
</div>
<div className='priceDiv'>
<div>
<span style={{display:'none'}} id='itemsPrcActive'>{data.price}</span>
<span style={{display:'none'}} id='itemsPrcActive2'>{data.price}</span>
<span>₹ <span id='itemsPrc'>{data.price}</span></span>
</div>
</div>
</div>

<div className='addItemBtn'>
<button onClick={() => aaddItems()}><i className='fa fa-plus'></i> Add Item</button>
</div>

</div>
)
})
}
</div>

</div>
</div>
</>
)
}

export default AddCartModal;
