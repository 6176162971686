import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const TabelBook = () => {
const { lisTabel, customers, userTabels } = GlobalContext();

const orderTabel = async(name,tabelNo) => {
var id = customers.length > 0 && customers[0].id;
var user = customers.length > 0 && customers[0].name;
var contact = customers.length > 0 && customers[0].contact;

if(lisTabel.length > 0){
lisTabel.forEach(element => {
if (element.name === name){
$('#tab_'+element.name).html('<img src="../images/leaves.png" alt="hug" class="spinImg" />').attr('disabled', 'disabled').css('background', 'teal');
} 
else if(element.name !== name && element.status === 'active'){
$('#tab_'+element.name).html('Waiting').attr('disabled', 'disabled').css('background', 'crimson');
}
else{
$('#tab_'+element.name).html('Order Now').attr('disabled', 'disabled').css('background', 'teal');
}
});
}

try {
const sendData = await axios.post('/server/updateUsers-tabel', {id: id, user: user, contact: contact, name: name, tabelNo: tabelNo});
const resData = await sendData.data;
if (resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Menu open successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.replace('/home');
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
if(lisTabel.length > 0){
lisTabel.forEach(element => {
if(element.name !== name && element.status === 'active'){
$('#tab_'+element.name).html('Waiting').attr('disabled', 'disabled').css('background', 'crimson');
}
else{
$('#tab_'+element.name).html('Order Now').removeAttr('disabled').css('background', 'teal');
}
});
}
},1000);    
}
} catch (error) {
console.log('frontend update tabel-api-error: ' + error); 
}
}

const orderTabelCont = async(name, tabelNo) => {
//alert(name +' -- '+ tabelNo);
var id = customers.length > 0 && customers[0].id;
$('#conttab_'+name).html('<img src="../images/leaves.png" alt="hug" class="spinImg" />').css('background', 'teal');
$('.bookBtn').attr('disabled', 'disabled');
$('.contBtn').attr('disabled', 'disabled');
try {
const sendData = await axios.post('/server/continue-tabel', {id: id, tabelNo: tabelNo});
const resData = await sendData.data;
if (resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Menu open successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.replace('/home');
},1000); 
return false;  
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
$('#conttab_'+name).html('Continue').css('background', 'teal');
$('.bookBtn').html('Order Now').removeAttr('disabled');
$('.contBtn').html('Continue').removeAttr('disabled');
},1000);   
}
} catch (error) {
console.log('frontend update-continue-tabel-api-error: ' + error); 
}
}

const continueData = lisTabel.filter((e) => userTabels.some((t) => e.name === t.tabelName && e.status === 'active'));
const waitingData = lisTabel.filter((e) => !userTabels.some((t) => e.name === t.tabelName));

return (
<>
<div className='tabelBookBody'>
<div className='container'>
<div className='tabelBook-content'>
<div className='tabelMain-title'><span>Select One Tabel To Your Order</span>
<hr className='hr2' />
</div>
<div className='cardsColWrap'>
<div className='cardsCol'>
{
lisTabel.length > 0 && lisTabel.map((data, index) => {
var tabelStatus = '';
var tabelBtn = '';
if (continueData.length > 0){
for(let i = 0; i < continueData.length; i++){
if(continueData[i].name === data.name){
tabelStatus = 'booked';
tabelBtn = <button id={'conttab_'+continueData[i].name} onClick={() => orderTabelCont(continueData[i].name,continueData[i].tabelNo)} className='contBtn'>Continue</button>; 
}
}  
}

if(waitingData.length > 0){
for(let i = 0; i < waitingData.length; i++){
if(waitingData[i].name === data.name && waitingData[i].status === 'active'){
tabelStatus = 'booked';
tabelBtn = <button id={'tab_'+waitingData[i].name} onClick={() => orderTabel(waitingData[i].name,waitingData[i].tabelNo)} className='waitBtn' disabled>Waiting</button>; 
}
else if(waitingData[i].name === data.name && waitingData[i].status === 'inactive'){
tabelStatus = 'open';
tabelBtn = <button id={'tab_'+waitingData[i].name} onClick={() => orderTabel(waitingData[i].name,waitingData[i].tabelNo)} className='bookBtn'>Order Now</button>;  
}
}   
}

return (
<div className='card' key={index}>
<img src='../images/chair.png' alt='chair' className='tabelImg' />
<span>{data.tabelNo}</span>
<span>Tabel Status : {tabelStatus}</span>
{tabelBtn}
</div>
)
})
}
</div>
</div>
</div>
</div>
</div>

</>
)
}

export default TabelBook;
