import React from 'react';
import $ from 'jquery';
import axios from 'axios';

const RemoveItem = () => {

const closeModal = () => {
$('#cartRemoveModal').hide();
}

const removeItems = async() => {
var id = $('#removeId').val();
try {
const sendData = await axios.post('/server/removeItem', {id: id});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Remove item successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend remove-item-cart-api error: ' + error);   
}
}

return (
<>
<div className='modals' id='cartRemoveModal'>
<div className='modals-content'>
<div className='modals-header'><span>Remove Items</span> <i className='fa fa-close' onClick={() => closeModal()}></i></div>

<div className='modals-Body'>
<div className='bodyContent-modal'>
<img src='../images/trash-bin.gif' alt='trash-bin' />
<input type='hidden' id='removeId' />
<span>are you sure? you want to remove cart items?</span>
<div className='yesNo-divs'>
<button onClick={() => removeItems()}>Yes</button>
<button onClick={() => closeModal()}>No</button>
</div>
</div>
</div>

</div>
</div>
</>
)
}

export default RemoveItem;
