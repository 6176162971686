import React from 'react';
import $ from 'jquery';
import axios from 'axios';

const EnteryPage = () => {

const confirmTabel = async() => {
var name = $('#customerName').val();
var contact = $('#customerContact').val();
//alert(name +' -- '+ contact +' -- '+ tabelNo);
if(name === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Customer name required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
$('#bookBtnLog').html('Book Order').removeAttr('disabled').css('background', 'crimson');
},1000);
return false;
}
if(contact.length < 10 || contact.length > 10){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('10 digit contact required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
$('#bookBtnLog').html('Book Order').removeAttr('disabled').css('background', 'crimson');
},1000);
return false;
}
else{
try {
$('#bookBtnLog').html('<img src="../images/leaves.png" alt="hug" class="spinImg" />').attr('disabled', 'disabled').css('background', 'teal');
const sendData = await axios.post('/server/customer-add', {name: name, contact: contact});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Tabel activeted successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.replace('/');
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
$('#bookBtnLog').html('Book Order').removeAttr('disabled').css('background', 'crimson');
},1000);
}    
} catch (error) {
console.log('backend customer-add-api error: ' + error);  
}
}
}

return (
<>
<div className='enteryPage'>
<div className='enteryContent'>
<div className='enteryLogo'>
<center>
<img src='../images/booking.gif' alt='booking' />
<span>Order Your Tabel</span>
<hr className='line1' />
</center>
</div>
<div>
<div className='input-div'>
<label>Your Name</label>
<input type='text' id='customerName' placeholder='Enter your name...' />
</div>
<div className='input-div'>
<label>Contact No</label>
<input type='number' id='customerContact' placeholder='Enter contact no...' />
</div>
<div className='inputDivSubmit'>
<button id='bookBtnLog' onClick={() => confirmTabel()}>Book Order</button>
</div>
</div>
</div>
</div>
</>
)
}

export default EnteryPage;
