import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';
import CartPrice from './CartPrice';
import RemoveAllItem from '../content/RemoveAllItem';
import RemoveItem from '../content/RemoveItem';

const API6 = '/server/list-cart';

const Cart = () => {
const { GetCartData, cartData, couponData, customers } = GlobalContext();

const removeItem = (id) => {
$('#cartRemoveModal').fadeIn().show();
$('#removeId').val(id);
}

const removeAllItem = () => {
$('#cartRemoveAllModal').fadeIn().show();
if(cartData.length > 0){
$('#removeAllId').val(cartData[0].contact);
}
}

const allListCou = () => {
$('#allCou').toggle();
}

const applyCou = async(name) => {
var contact = customers.length > 0 && customers[0].contact;
var tabelNo = customers.length > 0 && customers[0].tabelNo;
try {
const sendData = await axios.post('/server/updateCoupon-item', {name: name, tabelNo: tabelNo, contact: contact});
const resData = await sendData.data;
if (resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Coupon applied successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
GetCartData(API6);
});
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
});
}
} catch (error) {
console.log('frontend apply-coupon-api-item error: ' + error);
}
}

const reduceData = cartData.length > 0 && cartData.reduce((f, g) => {
const { couponName } = g;
let item = f.find((t) => t.couponName === couponName);
item ? item.couponName = couponName : f.push({couponName});
return f;
},[]);

useEffect(() => {
GetCartData(API6);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

return (
<>
<div className='bodyContent'>
<div className='bodyWrap'>
<div className='container'>
<div className='headTitlePage'>
<span>Cart (Item Total : {cartData.length})</span>
</div>

{
cartData.length > 0 && 
<div className='coupon-section'>
<div className='coupon-secWrap'>
<div className='CouponHead' onClick={() => allListCou()}>
<div className='col'>
<img src='../images/coupon.gif' alt='coupon' />
<span>Available Coupons</span>
</div>
<div className='col'>
<img src='../images/discount.gif' alt='discount' />
</div>
</div>

<div id='allCou'>
<div className='colCouWrap'>
{
couponData.length > 0 ? couponData.map((dat, ind) => {
reduceData && reduceData.map((c) => {
if(c.couponName === dat.name){
$('#applyCbtn_'+dat.name).html('Applied').css('background', 'teal');
}
else{
$('#applyCbtn_'+dat.name).html('Apply').css('background', 'crimson'); 
}
return null;
});
return (
<div className='colCou' key={ind}>
<div>
<span>{dat.name} Coupon</span>
<span>Discount : {dat.type === 'percent' ? '('+ dat.rate +' %)' : '('+ dat.rate +' ₹)'}</span>
<span>Coupon Type : {dat.type}</span>
<button id={'applyCbtn_'+dat.name} onClick={() => applyCou(dat.name)}>Apply</button>
</div>
</div>
)
})
:
<div className='colCou'>
<div><span>No Coupon Available</span></div>
</div>
}
</div>
</div>
</div>
</div>
}

<div className='products-page'>
{
cartData.length > 0 ? cartData.map((data, index) => {
var prdPrc = 0;
var itemType;

if(data.quantity === 'half'){
prdPrc = parseFloat(data.price) / 2;  
}
else{
prdPrc = parseFloat(data.price); 
}

if(data.types === 'veg'){
itemType = <span className='vegSpan'><span></span></span>;    
}
else{
itemType = <span className='nonvegSpan'><span></span></span>;  
}

return (
<div className='row' key={index}>
<div className='col'>
<div className='col2'><img src={'https://control.funandfood.co.in/'+data.path} alt='prdimg' /></div>
<div className='col2'>
<span className='product-title'>{itemType} {data.name}</span>
<span className='product-discription'>{data.discription}</span>
<div className='items-text'>
<span className='product-items'>Quantity :</span> <span className='product-text'>{data.quantity}</span>
<span className='product-items'>Item No :</span> <span className='product-text'>{data.stock}</span>
<span className='product-items'>Table No :</span> <span className='product-text'>{data.tabel}</span>
</div>
<span className='product-price'>Price:</span> <span className='product-priceAmt'>₹{prdPrc}</span>
<span className='product-price' style={{marginLeft: '5px'}}>Curr Price:</span> <span className='product-priceAmt'>₹{prdPrc +' X '+ data.stock +' = '+ parseFloat(prdPrc) * parseInt(data.stock)}</span>
</div>
</div>
<div className='col'>
<div className='add-btnsprd'>
<button className='addPrd' onClick={() => removeItem(data.id)}><i className='fa fa-minus'></i> Remove</button>
</div>
</div>
</div>
)
})
:
<div className='NoCarts'>
<img src='../images/empty-cart.png' alt='empty-cart' />
<span>no items in your cart... Please add some items...</span>
<Link to='/home'><button>Add Items</button></Link>
</div>
} 

</div>

{
cartData.length > 0 &&
<div className='viewmore-items'>
<div className='col'>
<Link to='/home'><button>Add Items</button></Link>
</div>
<div className='col'>
<button onClick={() => removeAllItem()}>Remove All</button>
</div>
</div>
}

{
couponData.length > 0 && couponData.filter((ex) => reduceData.length > 0 && reduceData.some((q) => ex.name === q.couponName)).map((exx, indd) => {
return (
<div className='couponStatus' key={indd}>
<div>
<img src='../images/coupon.gif' alt='coupon' />
<span>{exx.name} Coupon ({exx.type === 'percent' ? exx.rate +'%' : exx.rate +' ₹'})</span>
</div>
<div>
<span>Coupon Applied</span>
<img src='../images/double-check.gif' alt='double-check' />
</div>
</div>
)
}) 
}

{
cartData.length > 0 && <CartPrice />
}

</div>
</div>
</div>

<RemoveItem />
<RemoveAllItem />

</>
)
}

export default Cart;
