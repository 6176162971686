import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GlobalContext } from '../context/AppContext';

const OrderDetails = () => {

const { id } = useParams();
const { userOrders, tax, couponData } = GlobalContext();
const [filterData, setFilterData] = useState([]);

const reduceData = filterData.reduce((curr, elem) => {
const { contact, dateTime, itemQuantity, itemStock, itemPrice, orderId, orderStatus, payOpt, tabelNo, user, paymentBy, payStatus, couponName } = elem;
const item = curr.find((it) => it.orderId === orderId);
let newPrc = 0;
if(itemQuantity === 'half'){
newPrc = (parseFloat(itemPrice) * parseInt(itemStock)) / 2;
}
else{
newPrc = parseFloat(itemPrice) * parseInt(itemStock);
}
item ? item.newPrc += newPrc : curr.push({orderId, contact, dateTime, newPrc, orderStatus, payOpt, tabelNo, user, paymentBy, payStatus, couponName});
return curr;
},[]);

useEffect(() => {
if(userOrders.length > 0){
setFilterData(userOrders.filter((item) => item.orderId === id));
}
},[userOrders, id]);

return (
<>
<div className='bodyContent'>
<div className='bodyWrap'>
<div className='container'>
<div className='headTitlePage'>
<span>Orders Details</span>
</div>

<div className='products-page'>
{
reduceData.length > 0 ? reduceData.map((data, index) => {

const taxVal = tax.length > 0 && tax.reduce((h, j) => {
const { rate } = j;
h += (parseFloat(data.newPrc) * parseFloat(rate)) / 100;
return h;
},0);

const couponVal = couponData.length > 0 && couponData.filter((g) => g.name === data.couponName).reduce((x, v) => {
const { rate, type } = v;
let prc = 0;
if (type === 'percent'){
prc = (parseFloat(data.newPrc) * parseFloat(rate) / 100);
}
else{
prc = rate;
}
return x = prc;
},0);

return (
<div className='details-cards' key={index}>
<div className='ordersDetails'>

<div className='ordersDiv'>
<span className='product-title'>Item Details</span>
<hr className='hr1' />
{
userOrders.length > 0 && userOrders.filter((item) => item.orderId === data.orderId).map((data2, index2) => {
var itemType;
if(data2.itemTypes === 'veg'){
itemType = <span className='vegSpan'><span></span></span>;    
}
else{
itemType = <span className='nonvegSpan'><span></span></span>;  
}
  
return (
<div className='ordersDivWrapItem' key={index2}>
<div className='itemsNames'>
{itemType}
<span className='itemsTitle'>{data2.itemStock} X {data2.itemName} <span>({data2.itemQuantity})</span></span>
</div>
</div>
)
})
}
</div>

<div className='itemsFooter'>
<div><span>Order Status : </span> <span>{data.orderStatus}</span></div>
<div><div><span>Total Price : </span> <span>₹ {((parseFloat(data.newPrc) + parseFloat(taxVal)) - parseFloat(couponVal)).toFixed(2)}</span></div></div>
</div>

<div className='cou-set'>
{
couponData.length > 0 && couponData.filter((ex) => ex.name === data.couponName).map((exx, indd) => {
return (
<div className='couponStatus' key={indd}>
<div>
<img src='../images/coupon.gif' alt='coupon' />
<span>{exx.name} Coupon ({exx.type === 'percent' ? exx.rate +' %' : exx.rate +' ₹'})</span>
</div>
<div>
<span>Coupon Applied</span>
<img src='../images/double-check.gif' alt='double-check' />
</div>
</div>
)
}) 
}
</div>

<div className='ordersDiv'>
<span className='product-title'>Billing Details</span>
<hr className='hr1' />
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Price :</span></div>
<div className='ordersDivCol'><span>₹ {data.newPrc.toFixed(2)}</span></div>
</div>
{
couponVal > 0 &&
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Coupon Discount :</span></div>
<div className='ordersDivCol'><span>₹ {couponVal}</span></div>
</div> 
}
  
{
tax.length > 0 && tax.map((j, k) => {
let taxVal = (parseFloat(data.newPrc) * parseFloat(j.rate)) / 100;
return (
<div className='ordersDivWrap' key={k}>
<div className='ordersDivCol'><span>{j.name +' ('+ j.rate +'%)'} :</span></div>
<div className='ordersDivCol'><span>₹ {taxVal.toFixed(2)}</span></div>
</div>
)
})
}
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Total Price :</span></div>
<div className='ordersDivCol'><span>₹ {((parseFloat(data.newPrc) + parseFloat(taxVal)) - parseFloat(couponVal)).toFixed(2)}</span></div>
</div> 
</div>

<div className='ordersDiv'>
<span className='product-title'>Order Details</span>
<hr className='hr1' />
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Order Id :</span></div>
<div className='ordersDivCol'><span>{data.orderId}</span></div>
</div>
{
data.payOpt === 'tabel_order' &&
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Order Tabel :</span></div>
<div className='ordersDivCol'><span>{data.tabelNo}</span></div>
</div>
}
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Payment :</span></div>
<div className='ordersDivCol'><span>{data.paymentBy}</span></div>
</div>
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Payment Status:</span></div>
<div className='ordersDivCol'><span>{data.payStatus}</span></div>
</div>
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Order Date :</span></div>
<div className='ordersDivCol'><span>{data.dateTime}</span></div>
</div>
</div>

<div className='ordersDiv'>
<span className='product-title'>User Details</span>
<hr className='hr1' />
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Customer Name :</span></div>
<div className='ordersDivCol'><span>{data.user}</span></div>
</div>
<div className='ordersDivWrap'>
<div className='ordersDivCol'><span>Conatct No :</span></div>
<div className='ordersDivCol'><span>{data.contact}</span></div>
</div>
</div>
</div>
</div>
)
})
:
<div className='NoCarts'>
<img src='../images/oops.png' alt='empty-cart' />
<span>sorry something went wrong...Please refresh browser or try again later...</span>
<Link to='/home'><button>Add Items</button></Link>
</div>
}
</div>

</div>
</div>
</div>
</>
)
}

export default OrderDetails;
