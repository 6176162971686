import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const MyAccount = () => {
const { customers } = GlobalContext();

const updateProfile = async() => {
var id = $('#updateId').val();
var name = $('#customerName').val();
var contact = $('#customerContact').val();
var email = $('#customerEmail').val();
var street = $('#customerStreet').val();
var citym = $('#customerCity').val();
var state = $('#customerState').val();
var pincode = $('#customerPinCode').val();
//console.log(id +' -- '+ name +' -- '+ contact +' -- '+ email +' -- '+ street +' -- '+ citym +' -- '+ state +' -- '+ pincode);
if(name === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('Customer name required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(contact === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('10 digit contact required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server/update-users', {
id: id,
name: name,
contact: contact,
email: email,
street: street,
citym: citym,
state: state,
pincode: pincode
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Account updated successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;   
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend user-profile-update-api error: ' + error);
}
}
}

return (
<>
<div className='bodyContent'>
<div className='bodyWrap'>
<div className='container'>
<div className='headTitlePage'>
<span>My Account</span>
</div>

<div className='products-page'>
{
customers.length > 0 && customers.map((data, index) => {
return (
<div className='profile-content' key={index}>
<div className='input-div'>
<label>Your Name :</label>
<input type='hidden' id='updateId' defaultValue={data.id} />
<input type='text' id='customerName' placeholder='Enter your name...' defaultValue={data.name} />
</div>
<div className='input-div'>
<label>Contact Number :</label>
<input type='number' id='customerContact' placeholder='Enter contact number...' defaultValue={data.contact} />
</div>
<div className='input-div'>
<label>Email Id :</label>
<input type='text' id='customerEmail' placeholder='Enter email...' defaultValue={data.email} />
</div>
<div className='input-div'>
<label>Street Address :</label>
<input type='text' id='customerStreet' placeholder='Enter street address...' defaultValue={data.street} />
</div>
<div className='input-div'>
<label>City :</label>
<input type='text' id='customerCity' placeholder='Enter city name...' defaultValue={data.citym} />
</div>
<div className='input-div'>
<label>State :</label>
<input type='text' id='customerState' placeholder='Enter state name...' defaultValue={data.state} />
</div>
<div className='input-div'>
<label>Postal Code :</label>
<input type='text' id='customerPinCode' placeholder='Enter postal code...' defaultValue={data.pincode} />
</div>
<div className='input-divSubmit'>
<button onClick={() => updateProfile()}>Update</button>
</div>
</div>
)
})
}
</div>

</div>
</div>
</div>
</>
)
}

export default MyAccount;
