import { createContext, useContext, useEffect, useReducer } from "react";
import axios from 'axios';
import Reducer from "./Reducer";

const Context = createContext();

const API = '/server/list-category';
const API2 = '/server/list-products';
const API3 = '/server/list-tabel';
const API4 = '/server/active-customer';
const API5 = '/server/customer-ip';
const API6 = '/server/list-tax';
const API7 = '/server/list-orders';
const API8 = '/server/listAll-orders';
const API9 = '/server/usersTabel-details';
const API10 = '/server/list-coupon';
const API11 = '/server/store-info';
const API12 = '/server/store-social-info';
const API13 = '/server/list-orders-all';

const intialState = {
isLoading: false,
isError: false,
isUserLoading: false,
isUserError: false,
categoryData: [],
productData: [],
lisTabel: [],
customers: [],
userIp: [],
cartData: [],
cartPrice: [],
cartItems: [],
tax: [],
allOrders: [],
userOrders: [],
userTabels: [],
couponData: [],
storeInfo: [],
storeSocial: [],
listAllOrders: []
}

const Provider = ({children}) => {

const [state, dispatch] = useReducer(Reducer, intialState);

const GetCategory = async(URL) => {
dispatch({type: "CATEGORY_LOADING"});   
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: "CATEGORY_SET", payload: resData}); 
} catch (error) {
dispatch({type: "CATEGORY_ERROR"}); 
}
}

const GetProducts = async(URL) => {
dispatch({type: "PRODUCT_LOADING"});   
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: "PRODUCT_SET", payload: resData}); 
} catch (error) {
dispatch({type: "PRODUCT_ERROR"}); 
}
}

const GetAllTabel = async(URL) => {
dispatch({type: "TABEL_LOADING"});   
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: "TABEL_SET", payload: resData}); 
} catch (error) {
dispatch({type: "TABEL_ERROR"}); 
}
}

const GetCustomers = async(URL) => {
dispatch({type: "CUSTOMER_LOADING"});   
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: "CUSTOMER_SET", payload: resData}); 
} catch (error) {
dispatch({type: "CUSTOMER_ERROR"}); 
}
}

const GetIpData = async(URL) => {
dispatch({type: "IP_LOADING"});   
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: "IP_SET", payload: resData}); 
} catch (error) {
dispatch({type: "IP_ERROR"}); 
}
}

const GetCartData = async(URL) => {
dispatch({type: "CART_LOADING"});   
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: "CART_SET", payload: resData}); 
} catch (error) {
dispatch({type: "CART_ERROR"}); 
}
}

const GetTaxData = async(URL) => {
dispatch({type: "TAX_LOADING"});   
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: "TAX_SET", payload: resData}); 
} catch (error) {
dispatch({type: "TAX_ERROR"}); 
}
}

const GetAllOrders = async(URL) => {
dispatch({type: "ORDERS_LOADING"});   
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: "ORDERS_SET", payload: resData}); 
} catch (error) {
dispatch({type: "ORDERS_ERROR"}); 
}
}

const GetUserOrders = async(URL) => {
dispatch({type: "USER_ORDERS_LOADING"});   
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: "USER_ORDERS_SET", payload: resData}); 
} catch (error) {
dispatch({type: "USER_ORDERS_ERROR"}); 
}
}

const GetUserTabel = async(URL) => {
dispatch({type: "USER_TABEL_LOADING"});   
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: "USER_TABEL_SET", payload: resData}); 
} catch (error) {
dispatch({type: "USER_TABEL_ERROR"}); 
}
}

const GetCoupon = async(URL) => {
dispatch({type: "COUPON_LOADING"});   
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: "COUPON_SET", payload: resData}); 
} catch (error) {
dispatch({type: "COUPON_ERROR"}); 
}
}

const GetStoreInfo = async(URL) => {
dispatch({type: "STORE_DATA_LOADING"});   
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: "STORE_DATA_SET", payload: resData}); 
} catch (error) {
dispatch({type: "STORE_DATA_ERROR"}); 
}
}

const GetStoreSocial = async(URL) => {
dispatch({type: "STORE_SOCIAL_LOADING"});   
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: "STORE_SOCIAL_SET", payload: resData}); 
} catch (error) {
dispatch({type: "STORE_SOCIAL_ERROR"}); 
}
}

const GetListAllOrder = async(URL) => {
dispatch({type: "LIST_ALL_ORDERS_LOADING"});   
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: "LIST_ALL_ORDERS_SET", payload: resData}); 
} catch (error) {
dispatch({type: "LIST_ALL_ORDERS_ERROR"}); 
}
}

useEffect(() => {
GetCategory(API);
GetProducts(API2);
GetAllTabel(API3);
GetCustomers(API4);
GetIpData(API5);
GetTaxData(API6);
GetAllOrders(API7);
GetUserOrders(API8);
GetUserTabel(API9);
GetCoupon(API10);
GetStoreInfo(API11);
GetStoreSocial(API12);
GetListAllOrder(API13);
},[]);

return(
<Context.Provider value={{...state, GetCartData}}>{children}</Context.Provider>
)
}

const GlobalContext = () => {
return useContext(Context);
}

export {Context, Provider, GlobalContext}