import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import $ from 'jquery';
import { GlobalContext } from '../context/AppContext';
import AddCartModal from '../content/AddCartModal';

const Product = () => {

const { name } = useParams();
const { productData } = GlobalContext();
const [allData, setAlldata] = useState([]);
const [cart, setCart] = useState([]);

const addItem = (id) => {
setCart(productData.filter((item) => item.id === id));
$('#cartModal').fadeIn().show();
}

useEffect(() => {
setAlldata(productData.filter((item) => item.category === name));
},[productData, name]);

return (
<>
<div className='bodyContent'>
<div className='bodyWrap'>
<div className='container'>

<div className='headTitlePage'>
<span>{name}</span>
</div>

<div className='products-page'>
{
allData.length > 0 && allData.map((data, index) => {
return (
<div className='rowPro' key={index}>
<div className='col'>
<div className='col2'><img src={'https://control.funandfood.co.in/'+ data.path} alt='prdimg' /></div>
<div className='col2'>
<span className='product-title'>{data.name}</span>
<span className='product-discription'>{data.discription}</span>
<span className='product-price'>Price:</span> <span className='product-priceAmt'>₹{data.price}</span>
</div>
</div>
<div className='col'>
<div className='add-btnsprd'>
<button className='addPrd' onClick={() => addItem(data.id)}><i className='fa fa-plus'></i> Add</button>
</div>
</div>
</div>
)
})
}

{
allData.length < 1 &&
<div className='recordNot'>
<center><img src='../images/food-truck.gif' alt='no-item' /></center>
<span>Your food category item Not Found...</span>
</div>
}
</div>

</div>
</div>
</div>

<AddCartModal datas={cart} />
</>
)
}

export default Product;
