import React from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../context/AppContext';

const TopHeader = () => {

const { storeInfo, storeSocial } = GlobalContext();

return (
<>
<div className='top-header'>
<div className='container'>
<div className='rowTop'>
<div className='colTop'>
<div className='storeInfo'>
<img src='../images/namaste.gif' alt='social' className='prayImg' />
<span>Welcome To Our Restraunt Fun & Food</span>
{
storeInfo.length > 0 &&
<span className='contatSto'>
<img src='../images/phone.gif' alt='social' className='prayImg hideMob' style={{marginLeft: "10px"}} />
<span className='hideMob'>+{storeInfo[0].contact}</span>
</span>
}
</div>
</div>
<div className='colTop'>
<div className='socialWrap'>
{
storeSocial.length > 0 &&
<div className='socialList'>
<Link to={storeSocial[0].fb}><img src='../images/facebook.png' alt='social' /></Link>
<Link to={storeSocial[0].insta}><img src='../images/instagram.png' alt='social' /></Link>
<Link to={storeSocial[0].twitt}><img src='../images/twitter.png' alt='social' /></Link>
<Link to={storeSocial[0].ytube}><img src='../images/youtube.png' alt='social' /></Link>
</div>
}
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default TopHeader;
