import React from 'react';
import $ from 'jquery';
import { GlobalContext } from '../context/AppContext';

const ContactModal = () => {

const { storeInfo } =  GlobalContext();

const closeModal = () => {
$('#contactModal').hide();
}

return (
<>
<div className='modals' id='contactModal'>
<div className='modals-content-contact'>
{
storeInfo.length > 0 && storeInfo.map((data, index) => {
return (
<div className='modals-Body-contact' key={index}>
<div className='contact-wrapMod'>
<i className='fa fa-close contactClose' onClick={() => closeModal()}></i>
<span className='spanCon'><img src='../images/phone.gif' alt='phone' /> Get In Touch</span>
<hr className='hr3' />
<span>
<span className='spanStr'>+{data.contact}</span>, &nbsp;
<span className='spanStr'><i className='fa fa-envelope'></i> {data.email}</span>
</span>
<span className='spanStr'><i className='fa fa-map-marker'></i> Street: {data.street}</span>
<span className='spanStr'>
<span>City: {data.city}</span> / &nbsp;
<span>State: {data.state}</span> / &nbsp;
<span>Postal: {data.postal}</span>
</span>
</div>
</div>
)
})
}
</div>
</div>
</>
)
}

export default ContactModal;
