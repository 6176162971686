import React from 'react';
import $ from 'jquery';
import axios from 'axios';

const RemoveAllItem = () => {

const closeModal = () => {
$('#cartRemoveAllModal').hide();
}

const removeAll = async() => {
var contact = $('#removeAllId').val();
try {
const sendData = await axios.post('/server/removeAllItem', {contact: contact});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('Remove items successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('backend remove-all-items-cart-api error: ' + error);   
}   
}

return (
<>
<div className='modals' id='cartRemoveAllModal'>
<div className='modals-content'>
<div className='modals-header'><span>Remove All Items</span> <i className='fa fa-close' onClick={() => closeModal()}></i></div>

<div className='modals-Body'>
<div className='bodyContent-modal'>
<img src='../images/trash-bin.gif' alt='trash-bin' />
<input type='hidden' id='removeAllId' />
<span>are you sure? you want to remove all cart items?</span>
<div className='yesNo-divs'>
<button onClick={() => removeAll()}>Yes</button>
<button onClick={() => closeModal()}>No</button>
</div>
</div>
</div>

</div>
</div>
</>
)
}

export default RemoveAllItem;
